
import { UserInfo, UserService } from '@/services/user.service'
import { LocalStorageService } from '@/services/local-storage.service'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, onUnmounted, reactive } from 'vue'
import router from '@/router'

export default defineComponent({
  setup() {
    const injector = useReflectiveInjector();
    const userService = injector.get(UserService);
    const localStorageService = injector.get(LocalStorageService);

    const userInfo = reactive<Partial<UserInfo>>({});

    const userSub = userService.userInfo.subscribe((info) => {
      Object.assign(userInfo, info as UserInfo);
    });

    onUnmounted(() => {
      userSub.unsubscribe();
    });

    const clickLogout = () => {
      userService.logout(userInfo).then(() => {
        localStorageService.removeUserName()
        router.replace('/login')
      });
    };

    return {
      userInfo,
      clickLogout,
    };
  },
});
